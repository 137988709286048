.magazines {
    flex: 0.8;
    background-color: whitesmoke;
    padding: 40px 20px; 
    padding-bottom: 0;
    width: 100%;
    height: auto;
    
}

.magazines > h2 {
    margin-left: 5px;
    margin-bottom: 20px;
}

.magazines__events {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}

.magazines__events > * {
    border-radius: 10px;
    box-shadow: 0 1px 20px rgba(217, 217, 217, 0.98);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    background-color: whitesmoke;
    padding-bottom: 20px;
    margin-left: 5%;
}

@media screen and (max-width: 768px) {
    .magazines {
        flex: 0.8;
        background-color: whitesmoke;
        padding: 40px 20px; 
        padding-bottom: 0;
        width: 100%;
        height: auto;
    }
  }

  .line {
    height: 1px;
    background: slategrey;
    width: 60%;
    margin: 0 auto;
  }
