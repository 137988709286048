

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.read-more {
  align-self: center;
  padding: 10px;
  width: 300px;
  background-color: white;
  border-color: rgb(70, 70, 70);
  color: rgb(28, 28, 28);
  font-weight: bolder;
}
   
article h6{
  padding: 0px;
  font-size: 15px;
  display: flex;
  color: rgb(80, 80, 80);
  padding-top: 10px;
}
article {
  background-color: white;
  padding: 0px 170px 40px 170px;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .left-section, .right-section {
    width: 100%;
    padding: 0;
  }
  .image-container img {
      width: 100%;
    }

  .blue {
   color: #676969;
   font-size: 20px; 
  }
    
  .red {
   color: #cc0000;
   font-size: 20px;
  }

  header {
      padding: 5px;
      font-size: 16px;
      text-align: center;
      background: white;
  }
  header p {
    display: block;
  }

  article p {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: baseline;
  }

  .blog-toc-mob {
    flex: block;
  }

  article {
    background-color: white;
    padding: 0px 10px 10px 10px;
  }

  .left-section h2 {
    justify-content: center;
  }

  .left-section h6 {
    justify-content: center;
  }
}
  