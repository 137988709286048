.prayer_request {
  text-align: start;
  display: flex;
  background-image: url('./../../../images/prayer_request.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;    
  height: auto;
}

.prayer_request > header {
  font-size: 20px;
}

.prayer_request_body{
  background-color: rgb(244, 247, 248); 
  width: 40%;
  position: relative;
  left: 50%;
  padding: 40px;
  justify-content: center;
}

.prayer_request_body > h1 {
  font-size: 23px;
}

.prayer_request_body > h4{
  font-weight: lighter;
  color: slategrey;
  font-size: 20px;
}

.prayer_request_text_content {
  text-align: center;
  height: 200px;
}

.form_prayer {
  padding: 30px;
  font-size: 20px;
}

.prayer_request_img {
  height: 120px;
  margin-left: 90px;
  margin-bottom: 10px;
  display: block;
  border: 1px solid black;
  border-radius: 50%;
}

.prayer_request_header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:darkslategray;
  font-size: 20px;
}

.prayer_submit{
  background-color: lightslategrey;
  font-size: large;
  width: 62%;
  height: 45px;
}

.prayer_request_text{
 font-size: 23px;
}

.form_input {
  width: 90%;
  height: 30px;
  font-size: 20px;
}

.form_input_request {
  width: 90%;
  height: 150px;
  font-size: 20px;
}

@media screen and (max-width: 700px) {
  .prayer_request_body{
    background-color: white;
    width: 90%;
    position: relative;
    left: 5%;
    padding: 40px;
  }
}
.line {
  height: 1px;
  background: slategrey;
  width: 60%;
  margin: 0 auto;
}