.festival-container-video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.festival-container {
  display: flex;
  height: auto;
  flex-direction: column;
}

.festival-home {
  background: url('./../../../images/fatima_mission_web.png') center center/cover no-repeat; 
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;  
}

.fatima-mission {
  background: url('./../../../images/fatima_mission_web.png') center center/cover no-repeat; 
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;  
}

.festival-home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  z-index: -1;
}

.festival-title-text {
  color: white;
  filter: brightness(1);
}


@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .festival-home {
    background: url('./../../../images/fatima_mission_mob.png') center center/cover no-repeat; 

  }

  .fatima-mission {
    background: url('./../../../images/fatima_mission_mob.png') center center/cover no-repeat; 
  }
}

@media screen and (max-width: 768px) {
  .festival-video-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: auto;
  }
  .festival-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: auto;
  }

  .festival-container > video {
    object-fit: cover;
    height: 100vh;
    filter: brightness(0.8);  
  }

  .festival-home {
    background: url('./../../../images/fatima_mission_mob.png') center center/cover no-repeat; 
  }

  .fatima-mission {
    background: url('./../../../images/fatima_mission_mob.png') center center/cover no-repeat;
  }
  
}
