video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  filter: brightness(0.5);  
  background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%); /* create gradient with black color at start and end points */
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;  
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 65px;
  margin-right: 62px;
}

.hero-title-text {
 color: white;
 font-size: 50px;
 text-align: left;
 padding-left: 150px;
 padding-top: 10px;

}

.hero-container > p {
  margin-top: 10px;
  margin-right: 60px;
  margin-bottom: 115px;
  color: #fff;
  font-size: 24px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 52px;
  width: 100%;
}

.hero-btns .btn {
  margin: 6px;
}

.read-more-button {
  background-color: rgb(255, 229, 62);
  border: 1px solid rgb(206, 151, 0);
  border-radius: 8px; /* set the border radius to make the button round */
  color: #000;
  width: 200px;
  height: 48px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  margin-left: 150px;
  transition: all 0.3s ease-in-out;
  margin-top: 50px;
}

.read-more-button:hover {
  background-color: #000;
  color: #fff;
}

.fa-play-circle {
  margin-left: 4px;
}

.multi-color-div {
  font-size: 24px;
  line-height: 1.4;
  color: #333;
  text-align: left;
  padding-left: 150px;
  width: 70%;
  margin: 1em 0;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.hero-subtitle-text {
  color: white;
  font-size: 22px;
  font-weight: 100;
}

.author {
  color: rgb(219, 219, 219);
  font-size: 22px;
  font-weight: 100;
  font-style: italic;
  padding-left: 150px;
}

.white-space {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  align-items:flex-end;
  height: 650px;
  background-color: rgb(254, 252, 252);
  width: 100%;
  border-color: white;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .white-space {
    align-self: flex-end;
    height: 700px;
    padding-bottom: 20px;
    background-color: #000;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .video-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: auto;
  }
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
  }

  video {
    object-fit: cover;
    height: 100vh;
    filter: brightness(0.7);  
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-title-text {
    display: none;
  }
  

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .white-space {
    height: 500px;
  }

  .hero-title-text {
    color: white;
    font-size: 40px;
    text-align: left;
    padding-left: 20px;
   }

   .multi-color-div {
    line-height: 1.4;
    color: #333;
    text-align: left;
    padding-left: 20px;
    width: 90%;
    margin: 1em 0;
  }
  
  .hero-subtitle-text {
    color: white;
    font-size: 24px;
    font-weight: 400;
  }
  
  .author {
    color: rgb(219, 219, 219);
    font-size: 22px;
    font-weight: 100;
    padding-left: 20px;
  }

  .read-more-button {
    font-size: 20px;
    font-weight: medium;
    margin-left: 20px;
  }
  
  .read-more-button:hover {
    background-color: #000;
    color: #fff;
  }

  .hero-btns {
    padding-left: 20px;
    display: flex;
    justify-content: center;
  }
}
