.festival {
    flex: 0.8;
    background-color: white;
    padding: 40px 20px;
    padding-bottom: 0;
    width: 100%;
    height: auto;
    justify-content: center;

}

.festival > h2 {
    margin-left: 5px;
    margin-bottom: 20px;
    color: rgb(3, 4, 4);
    font-weight: 100;
    font-size: large;
    font-family:Verdana, Geneva, Tahoma, sans-serif
}

.festival>h1 {
    color: darkslategrey;
    text-align: center;
    padding-bottom: 15px;
}

.festival__events {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.festival__events>* {
    margin-left: 5%;
}

.see-more-button {
    background-color: rgb(255, 229, 62);
    border: 1px solid rgb(206, 151, 0);
    border-radius: 8px; /* set the border radius to make the button round */
    color: #000;
    width: 200px;
    height: 48px;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    margin-top: 50px;
  }

  .video-modal {
    width: 80%;
    height: 80%;
    max-width: 800px;
    max-height: 600px;
    margin: 0 auto;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-button {
    width: 100px;
    height: 30px;
    position: fixed;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


@media screen and (max-width: 768px) {
    .festival {
        flex: 0.8;
        background-color: white;
        padding: 40px 20px;
        padding-bottom: 0;
        width: 100%;
        height: auto;
    }
    .see-more-button {
        background-color: rgb(255, 229, 62);
        border: 1px solid rgb(206, 151, 0);
        border-radius: 8px; /* set the border radius to make the button round */
        color: #000;
        width: 200px;
        height: 48px;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        margin-top: 50px;
      }
      .festival__events>* {
        margin-left: 0%;
    }
    
}