.blog_item {
    width: 330px;
    margin-bottom: 10px;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.blog_item__image {
    height: 200px;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    object-fit: cover;
}

.blog_item__info {
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
}

.blog_item__text {
    display: flex;
}

.blog_item__text>h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.blog_item__text>p {
    font-size: 14px;
    font-weight: lighter;
    color: rgb(0, 0, 0);
}
.blog_item__avatar {
    height: 30px !important;
    width: 30px !important;
}

.videocard__text>* {
    margin: 1px;
}

.blog_item_title{
    text-align: start;
}
.blog_item_title>p{ 
    font-size: small;
    padding: 0px 0px;
    color: darkslategrey;
}
.blog_item_date>h5{
    color: lightslategray;
    font-weight: lighter;
}
.blog_item_date>h4{
    color: lightslategray;
    font-weight: normal;
}

.blog_item_date>h1{
    color: darkslategray;
    font-weight: bold;
    font-size: xx-large;
    padding-bottom: 0px;
}
.blog_item_title>a{
    text-decoration: none;
    color: rgb(0, 110, 220);
    font-size: 12px;
}

.video-container {
    position: relative;
    width: fit-content;
    display: inline-block;
  }

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    background-color: rgba(246, 241, 241, 0.6);
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .play-button.pause {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .play-button i {
    border: none;
    color: black;
  }


  @media screen and (max-width: 768px) {
    .blog_item {
        width: 330px;
        margin-bottom: 10px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
        filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    
    }
    
    .blog_item__image {
        height: 80px;
        width: 70px;
        border-radius: 10px 10px 10px 10px;
        object-fit: cover;
    }

    .blog_item__text {
        padding-left: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    
}
  