.magazine_card {
    margin-bottom: 40px;
    border-radius: 10px;
}

.magazine_card__image {
    height: 300px;
    width: 240px;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
}

.magazine_card__info {
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
    justify-content: center;
}

.magazine_card__text {
    margin-left: 15px;
}

.magazine_card__text > h4 {
    margin-bottom: 5px;
}

.magazine_card__text > p {
    font-size: 14px;
    font-weight:lighter;
    color: rgb(50, 50, 50);
}


.upcoming_event_item__avatar {
    height: 30px !important;
    width: 30px !important;
}



.videocard__text > * {
    margin: 1px;
}
