@import '~react-image-gallery/styles/css/image-gallery.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

.home,
.services,
.products,
.timeline,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.prayer-button {
  background-color: #80eb83;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.prayer-button:hover {
  background-color: #45a049;
}
