/* SubscribeBanner.css */
.white-container {
    background-color: white;
    padding: 20px; /* Adjust padding as needed */
    border-radius: 0px; /* Add border-radius for rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add box-shadow for a subtle shadow effect */
  }
  
  .subscribe-banner {
    position: relative;
  }
  
  .subscribe-banner .subscribe-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .subscribe-banner .subscribe-content {
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2); /* Transparent black background */
    padding: 50px;
  }
  
  .subscribe-banner .content-box {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .subscribe-banner button {
    background-color: #3498db;
    width: 200px;
    color: white;
    padding: 8px 16px;
    margin-top: 50px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscribe-banner button:hover {
    background-color: #2980b9;
  }
  