.toc-container {
    padding: 10px;
    margin-bottom: 2px;
    background-color: rgb(249, 252, 252);
  }
  
  .toc-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
  }
  
  .toc-list {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-items: left;
  }
  
  .toc-list li {
    margin-bottom: 5px;
    line-height: 2.0;
    font-weight: bolder;

  }
  
  .toc-list li a {
    color: rgb(86, 86, 86);
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    display:flex;
  }
  
  .toc-list li a:hover {
    color: rgb(0, 106, 255);
  }
  