.upcoming_event_item {
    width: 330px;
    margin-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0 1px 20px rgba(174, 174, 174, 0.98);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    background-color: whitesmoke;
    padding-bottom: 20px;

}

.upcoming_event_item:hover {
    transform: scale(1.1);
}

.upcoming_event_item__image {
    height: 150px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;

}

.upcoming_event_item__info {
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
}

.upcoming_event_item__text {
    margin-left: 15px;
    display: flex;
}

.upcoming_event_item__text>h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.upcoming_event_item__text>p {
    font-size: 14px;
    font-weight: lighter;
    color: rgb(0, 0, 0);
}
.upcoming_event_item__avatar {
    height: 30px !important;
    width: 30px !important;
}

.videocard__text>* {
    margin: 1px;
}

.upcoming_event_title{
    padding-left: 20px;
    text-align: start;
}
.upcoming_event_title>p{ 
    font-size: small;
    padding: 0px 0px;
    color: darkslategrey;
}
.upcoming_event_date>h5{
    color: lightslategray;
    font-weight: lighter;
}
.upcoming_event_date>h4{
    color: lightslategray;
    font-weight: normal;
}

.upcoming_event_date>h1{
    color: darkslategray;
    font-weight: bold;
    font-size: xx-large;
    padding-bottom: 0px;
    white-space: nowrap;
}
.upcoming_event_title>a{
    text-decoration: none;
    color: rgb(0, 110, 220);
    font-size: 12px;
}