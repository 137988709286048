.videocard {
    width: 270px;
    margin-bottom: 40px;
    box-shadow: 0 1px 20px rgba(174, 174, 174, 0.48);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    background-color: whitesmoke;
    padding-bottom: 10px;
    border-radius: 5px;
}

.videocard__image {
    height: 140px;
    width: 250px;
    padding-top: 5px;

}

.videocard__info {
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
    text-align: left;
}

.videocard__text {
    margin-left: 15px;
    
}

.videocard__text > h4 {
    font-size: 13px;
    margin-bottom: 5px;
    color: black;
}

.videocard__text > p {
    font-size: 12px;
    color: gray;
}

.videocard__avatar {
    height: 30px !important;
    width: 30px !important;
}



.videocard__text > * {
    margin: 1px;
}
