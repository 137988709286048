.blog-container {
    padding: 20px;
    background: #ffffff;
  }
  .blog-toc-mob {
    display: none;
  }
  
  header {
    padding-bottom: 30px;
    font-size: 40px;
    text-align: center;
    background: white;
  }
  
  main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .left-section {
    width: 75%;
    text-align: left;
  }

  .right-section {
    width: 25%;
  }
  .right-section h2,h3{
    display: flex;
  }
  .right-section-subtext{
    display: flex;
    padding-left: 0%;
  }
  article {
    background-color: white;
    padding: 0px 170px 40px 170px;
    text-align: justify;
  }
  article h6{
    padding: 0px;
    font-size: 15px;
    display: flex;
    color: rgb(80, 80, 80);
    padding-top: 10px;
  }
  
  header h2 {
    margin-top: 0;
    font-size:x-large;
    font-weight:bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
  }
  
  header p {
    margin-bottom: 0;
    text-align: center;
    font-size: large;
    padding-top: 20px;
    display: flex;
  }
  
  .image-container {
    margin-bottom: 20px;
    text-align: start;
    align-content: flex-start;
    display: flex;
  }

  .blog-container img {
    width: 100%;
    height: auto;
  }
  
  blockquote {
    margin: 20px 0;
    padding: 20px;
    border-left: 5px solid #ccc;
    text-align: left;
}

.multi-color-quote {
    font-size: 24px;
    line-height: 1.4;
    color: #333;
    margin: 1em 0;
  }
  
  .blue {
    color: #000000;
    font-size: 24px;
  }
  
  .red {
    color: #ff4545;
    font-size: 24px;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position:inherit;
    width: 750px;
    height: 400px;
  }


/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
    .left-section {
      width: 100%;
      padding: 0;
      text-align: justify;
    }
   .right-section {
      width: 100%;
      padding: 0;
    }
    .blog-container img {
        width: 100%;
        height: auto;
      }

    .blue {
     color: #242525;
     font-size: 18px; 
    }
      
    .red {
     color: #cc0000;
     font-size: 18px;
    }

    header {
        padding: 5px;
        font-size: 16px;
        text-align: center;
        background: white;
    }
    header p {
      display: block;
    }

    article p {
      background-color: white;
      padding: 20px;
      margin-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .blog-toc-mob {
      flex: block;
    }

    article {
      background-color: white;
      padding: 0px 10px 10px 10px;
    }

  .image-container {
    margin-bottom: 20px;
    text-align: start;
    align-content: flex-start;
    display: block;
  }

  article h6{
    text-align: center;
    justify-content: center;
  }

  article h2{
    text-align: center;
    justify-content: center;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position:inherit;
    width: 100%;
    height: 200px;
  }

  .multi-color-quote {
    font-size: 24px;
    line-height: 1.4;
    margin: 1em 0;
  }

  }
   
  