@import url('https://fonts.googleapis.com/css?family=Monoton');
.podcastsDiv {
  height: 100vh;
  position: relative;
  display: flex;
  background-color: #fbfbf8;
  justify-content: left;
  padding-left: 100px;
  background-image: url('./../../../images/podcast_bgg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.podcastsDiv .backgroundColorDiv {
  background-color: #fbfbf8;
  position: absolute;
  height: auto;
  width: 100%;
  z-index: -1;
  top: 50px;
  left: 0px;
}

.podcastsDiv .contentDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: left;
  color: #3c3b37;
  line-height: 1.4;
  margin-left: 100px;
}

.podcastsDiv .contentDiv .heading {
  font-size: 60px;
  margin-bottom: 10px;
  color: rgb(83, 1, 83);
  font-family:  'Monoton', cursive;
}

.podcastsDiv .contentDiv .about {
  font-size: 22px;
  margin-bottom: 5px;
}
.podcastsDiv .contentDiv .about_second {
  font-size: 15px;
  margin-bottom: 25px;
}
.podcastsDiv .contentDiv .podcastPretitle {
  font-size: 22px;
  color: rgb(116, 2, 116);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

 .button1 {
  width: 180px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  height: 50px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  background-image: url('./../../../images/g_podcast.svg');;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 1em;
}

.button2 {
  width: 180px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 50px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  background-color: white;
  background-image: url('./../../../images/spotify_bg.png');;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 1em;
  position: relative;
  background-origin: content-box;
  padding: 6px;
}
.podcastsDiv .contentDiv .button:hover {
  background-color: transparent;
}

@media screen and (max-width: 700px) {
  .podcastsDiv{
    height: auto;
    position: relative;
    display: flex;
    background-color: #fbfbf8;
    justify-content: center;
    padding-left: 10px;
    background-image: url('./../../../images/podcast_mob_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .podcastsDiv .contentDiv {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    text-align: center;
    color: #3c3b37;
    line-height: 1.4;
    margin-left: 10px;
  }

  .podcastsDiv .contentDiv .heading {
    font-size: 45px;
    margin-bottom: 5px;
    color: rgb(83, 1, 83);
    font-family:  'Monoton', cursive;
    margin-bottom: 50px;
  }
  
  .podcastsDiv .contentDiv .about {
    font-size: 11px;
    margin-bottom: 2px;
  }
  .podcastsDiv .contentDiv .about_second {
    font-size: 9px;
    margin-bottom: 25px;
  }
  .podcastsDiv .contentDiv .podcastPretitle {
    font-size: 25px;
    color: rgb(116, 2, 116);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .button1 {
    margin-left: 80px;
  }
  .button2 {
    margin-left: 80px;
  }
}
