.blog {
    flex: 0.8;
    background-color: white;
    padding: 40px 20px;
    padding-bottom: 0;
    width: 100%;
    height: auto;
    justify-content: center;

}

.blog > h2 {
    margin-left: 5px;
    margin-bottom: 20px;
    color: rgb(3, 4, 4);
    font-weight: 100;
    font-size: large;
    font-family:Verdana, Geneva, Tahoma, sans-serif
}

.blog>h1 {
    color: darkslategrey;
    text-align: center;
    padding-bottom: 15px;
}

.blog__events {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.blog__events>* {
    margin-left: 5%;
}

.read-article-button {
    align-self: center;
    padding: 10px;
    width: 300px;
    background-color: white;
    border-color: rgb(70, 70, 70);
    border-width: 1px;
    color: rgb(28, 28, 28);
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .video-modal {
    width: 80%;
    height: 80%;
    max-width: 800px;
    max-height: 600px;
    margin: 0 auto;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close-button {
    width: 100px;
    height: 30px;
    position: fixed;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


@media screen and (max-width: 768px) {
    .blog {
        flex: 0.8;
        background-color: white;
        padding: 40px 20px;
        padding-bottom: 0;
        width: 100%;
        height: auto;
    }
    .read-article-button {
        align-self: center;
        padding: 10px;
        width: 300px;
        background-color: white;
        border-color: rgb(70, 70, 70);
        color: rgb(28, 28, 28);
        font-weight: bolder;
        margin-bottom: 40px;
      }
      .blog__events>* {
        margin-left: 0%;
    }
    
}