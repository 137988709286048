@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

body {
  text-align: center;
  margin: 0 auto;
  font-family: "Poppins", "sans-serif";
  background-color: rgb(254, 254, 254);
  height: auto;
}

@media screen and (max-width: 768px) {
  .leftCard {
    display: none;
  }

  .rightCard {
    display: none;
  }

  .active {
    z-index: 1;
    height: 400px;
    border-radius: 10px;
  }
  

  .testimonial_word > h1 {
    font-size: small;
    color: rgb(75, 75, 75);
    font-style: italic;
  }

}

.carousel-container {
  display: flex;
  background-color: rgb(221, 220, 220);
  flex-flow: column;
  padding: 40px 20px; 
  width: 100%;
  height: auto;
}

.line {
  height: 1px;
  background: slategrey;
  width: 60%;
  margin: 0 auto;
}

.avatars {
  border-radius: 50%;
  height: 100px;
  margin-top: -6em;
}

.cards {
  display: flex;
  width: 100%;
}

.card {
  margin: 5em 0.5em 0em;
  background-color: rgb(51, 51, 51);
  width: 35%;
  padding: 1em;
  flex: 1; /* each card is of equal size */
  box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 6px 6px 17px 1px rgba(0, 0, 0, 0.36);
}

.leftCard {
  margin-right: -15em;
  margin-top: 7em;
  background-color: rgb(223, 223, 225);
}

.rightCard {
  margin-left: -15em;
  margin-top: 7em;
  background-color: rgb(223, 223, 225);
}

.testimonial_word > h1 {
  font-size: medium;
  color: rgb(78, 78, 78);
  font-style: italic;
}

.active {
  z-index: 1;
  background-repeat: no-repeat;
  background-position-x: 0, right;
  background-position-y: 0, bottom;
  background-size: 30px;
  background-origin: content-box;
  border-radius: 10px;
}

.arrow-container {
  display: flex;
  justify-content: space-between;
  margin-top: -10em;
  position: relative;
}

.testimony {
  margin: 1em;
  font-size: small;
}

.arrow {
  height: 2em;
  fill: rgb(254, 252, 252);
}

.arrow:active {
  fill: rgb(254, 252, 252);
}

.name {
  margin-top: 0.5em;
  margin-bottom: 1.0em;

}
.position {
  margin-top: 0.5em;
  margin-bottom: 1.0em;
}

.testimony {
  margin-top: 0.5em;
  margin-bottom: 1.0em;
}

p {
  padding: 0em 1em;
}

.text {
  height: 90%;
  overflow: hidden;
  color: rgb(255, 255, 240);
  font-size: small;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.text_inactive {
  height: 90%;
  overflow: hidden;
  color: rgb(71, 71, 71);
  font-size: small;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.tracker {
  display: flex;
  justify-content: center;
  height: 2.5em;
  margin-top: 1em;
}

.circle {
  transform: scale(0.1);
  width: 40px;
}

.viewAll {
  background-color: white;
  border: none;
  border-radius: 25px;
  height: 2em;
  width: 20%;
  font-weight: bold;
  margin: 2em auto;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.36);
  -webkit-box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.36);
}

.viewAll:hover {
  background-color: lightgray;
}

.viewAll:active {
  background-color: gray;
}